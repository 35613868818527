import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, IconDefinition, faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { areTrackInfosSame, togglePlayPause } from "./buttonUtils";
import styled from "styled-components";
import AudioPlayerContext, { TrackInfo } from "../../context/AudioPlayerContext";

interface ButtonProps {
  small?: boolean;
  white?: boolean;
  onClick: () => void
  icon: IconDefinition;
  disabled?: boolean;
}

interface ConfiguredButton {
  small?: boolean;
  white?: boolean;
}

const ButtonEl = styled.button<{
  small?: boolean;
  white?: boolean;
}>`
  background: transparent;
  padding: ${props => (props.small ? '13px' : '40px')};
  font-size: ${props => (props.small ? '15px' : '48px')};
  cursor: pointer;
  border-radius: 50%;
  border: ${props => (props.small ? 'none' : '2px solid gray')};
  width: ${props => (props.small ? '15px' : '60px')};
  height: ${props => (props.small ? '15px' : '60px')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.white ? 'white' : 'var(--color-link)')};
`;

const Button: React.FC<ButtonProps> = ({ small, white, onClick, icon, disabled }) => {

  return (
    <ButtonEl small={small} white={white} disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon opacity={disabled ? 0.5 : 1} fontSize={small ? '15px' : ''} icon={icon} />
    </ButtonEl>
  );
};

export const ForwardButton: React.FC<ConfiguredButton> = ({ small, white }) => {
  const audioPlayerContext = React.useContext(AudioPlayerContext);

  if (!audioPlayerContext) {
    return null;
  }

  const {
    switchToTrack,
    currentTrack,
    trackInfo: ctxTrackInfo
  } = audioPlayerContext;

  const [isActive, setIsActive] = React.useState(true)

  React.useEffect(() => {
    if (currentTrack && ctxTrackInfo) {
      const i = ctxTrackInfo.trackList.findIndex(t => t.title === currentTrack.title)
      setIsActive(i < ctxTrackInfo.trackList.length - 1)
    }
  }, [currentTrack])

  const next = () => {
    if (currentTrack && ctxTrackInfo) {
      const i = ctxTrackInfo.trackList.findIndex(t => t.title === currentTrack.title)
      if (i < ctxTrackInfo.trackList.length - 1) {
        switchToTrack(i + 1);
      }
    }
  }



  return <Button small={small} white={white} onClick={next} disabled={!isActive} icon={faForwardStep} />

}


const PlayPauseButton: React.FC<ConfiguredButton & {
  trackInfo: TrackInfo;
}> = ({ small, white, trackInfo }) => {
  const audioPlayerContext = React.useContext(AudioPlayerContext);

  if (!audioPlayerContext) {
    return null;
  }

  const {
    audioRef,
    isPlaying,
    setIsPlaying,
    trackInfo: ctxTrackInfo,
    setTrackInfo
  } = audioPlayerContext;


  const [isCurrentTrackInfoLoaded, setIsCurrentTrackInfoLoaded] = React.useState(false)

  React.useEffect(() => {
    setIsCurrentTrackInfoLoaded(areTrackInfosSame(ctxTrackInfo, trackInfo))
  }, [ctxTrackInfo, trackInfo])

  const click = () => {
    if (!isCurrentTrackInfoLoaded) {
      setTrackInfo(trackInfo)
      return
    }
    togglePlayPause(isPlaying, audioRef.current, setIsPlaying)
  }

  return <Button small={small} white={white} onClick={click} icon={isPlaying ? faPause : faPlay} />
}

export { PlayPauseButton };
