import * as React from "react";
import styled from "styled-components";
import { formatTime } from "./displayUtils";
import { Link } from "gatsby";
import { ForwardButton, PlayPauseButton } from "./button";
import { TrackInfo } from "../../context/AudioPlayerContext";

interface DisplayProps {
  currentTrack: Track | null;
  currentTime: number;
  duration?: number;
  trackInfo: TrackInfo;
}

interface Track {
  title: string;
  timestamp: string;
}


const InnerDiv = styled.div`
  display: flex;
  align-items: baseline;
  color: white;
  font-size: smaller;
`;

const Col = styled.div`
  display:flex;
  flex-direction: column;
`


const Row = styled.div`
  display:flex;
`

const SpanAlignRight = styled.span`
  text-align: left;
`;

const Display: React.FC<DisplayProps> = ({ currentTrack, currentTime, duration, trackInfo }) => {
  return (
    <InnerDiv>
      <Col style={{ marginRight: 'var(--spacing-4)', minWidth: '80px' }}>
        <Row>
          <PlayPauseButton trackInfo={trackInfo} small white />
          <ForwardButton small white />
        </Row>
        {duration && <SpanAlignRight>
          {formatTime(currentTime)} / {formatTime(duration)}
        </SpanAlignRight>}
      </Col>
      <Col>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', height: '1.5em' }}>{currentTrack ? currentTrack.title : "No track selected"}</div>
        <div>
          <Link style={{ color: "white", overflow: 'hidden', textOverflow: 'ellipsis', height: '1.5em' }} to={trackInfo.playlistUrl}>{trackInfo.playlistName}</Link>
        </div>
      </Col>
    </InnerDiv>
  );
};

export default Display;
