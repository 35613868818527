import * as React from "react";
import { generateWaveform, updateCurrentSegment } from "./waveformUtils";
import styled from "styled-components";
import { BarLoader } from "react-spinners";
import { AudioMetadata } from "../../context/AudioPlayerContext";

interface WaveformProps {
  audioMetaData: AudioMetadata | null;
  currentTime: number;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  samplesRef: React.MutableRefObject<number[]>;
  onClick: (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => void;
}

const Canvas = styled.canvas`
  cursor: pointer;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: white;
  width: 100%;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Waveform: React.FC<WaveformProps> = ({
  audioMetaData,
  currentTime,
  canvasRef,
  samplesRef,
  onClick,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const generate = async () => {
      if (audioMetaData && audioMetaData?.data) {
        setIsLoading(true);
        generateWaveform(audioMetaData.data, canvasRef, samplesRef);
        setIsLoading(false);
      }
    };
    generate();
  }, [audioMetaData, canvasRef, samplesRef]);

  React.useEffect(() => {
    if (canvasRef.current && samplesRef.current && audioMetaData?.duration) {
      updateCurrentSegment(currentTime, audioMetaData.duration, canvasRef, samplesRef);
    }
  }, [currentTime, audioMetaData, canvasRef, samplesRef]);

  return (
    <>
      {isLoading && true && <LoadingMessage><BarLoader color="white" /></LoadingMessage>}
      <Canvas ref={canvasRef} height={40} width={window.innerWidth - 50} onClick={onClick} />
    </>
  );
};

export default Waveform;
