export const loadAudio = async (src: string): Promise<{
  data: number[],
  duration: number
} | null> => {
  try {
    const response = await fetch(src);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error processing audio:", error);
    return null;
  }
};

export const parseTimestamp = (timestamp: string): number => {
  const [minutes, seconds] = timestamp.split(":");
  return parseInt(minutes) * 60 + parseInt(seconds);
};
