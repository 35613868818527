const SEGMENT_WIDTH_PX = 6;
const GAP_PX = 2; // Maintain existing gap

const ACTIVE_COLOR = { r: 161, g: 22, b: 22};
const INACTIVE_COLOR = { r: 255, g: 255, b: 255 };


// Function to draw a rounded rectangle manually
export const drawRoundedRect = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number, color: string) => {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};

export const getCalculationSize = (canvas: HTMLCanvasElement) => {
  // Get actual pixel width using getBoundingClientRect()
  const widthInPixels = canvas.getBoundingClientRect().width;
  const height = canvas.height;
  // Calculate segment count based on fixed 5-pixel width

  const pixelToWidth = widthInPixels / canvas.width;
  const segmentWidth = SEGMENT_WIDTH_PX / pixelToWidth;
  const gap = GAP_PX / pixelToWidth;
  const segmentCount = Math.floor(widthInPixels / (SEGMENT_WIDTH_PX + GAP_PX));

  return { segmentCount, segmentWidth, gap, widthInPixels, height };
};

const getSamples = ({ data, segmentCount, }:
  {
    segmentCount: number;
    data: number[];
  }): number[] => {

  // Placeholder for actual sample retrieval logic

  const segmentLength = data.length / segmentCount;

  // Prepare samples
  const samples: number[] = [];
  for (let i = 0; i < segmentCount; i++) {
    const start = i * segmentLength;
    const end = start + segmentLength;
    const segment = data.slice(start, end);

    // Use reduce instead of spread operator
    const average = array => array.reduce((a, b) => a + b) / array.length;
    //  const max = segment.reduce((max, val) => Math.max(max, Math.abs(val)), 0);f
    const max = average(segment)
    samples.push(max);
  }
  return samples
};

export const generateWaveform = async (data: number[], canvasRef: React.RefObject<HTMLCanvasElement>, samplesRef: React.MutableRefObject<number[]>) => {
  const canvas = canvasRef.current;
  if (!canvas) return;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const { segmentCount, segmentWidth, gap, widthInPixels, height } = getCalculationSize(canvas);

  // Clear the canvas
  ctx.clearRect(0, 0, widthInPixels, height);

  // Set background
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, widthInPixels, height);

  const samples = getSamples({
    data,
    segmentCount,
  })
  // Store samples for later use
  samplesRef.current = samples;

  // Normalize the samples
  const maxSample = Math.max.apply(null, samples);
  const normalizedSamples = samples.map(s => s / maxSample);


  // Draw the waveform as separated vertical bars
  const cb = drawSample({ height, segmentWidth, gap, ctx, currentSegment: undefined, samplesRef, currentTime: 0, duration: 1 })
  normalizedSamples.forEach(cb);
};

const cubicBezier = (t: number, p0: number, p1: number, p2: number, p3: number): number => {
  const u = 1 - t;
  return u * u * u * p0 + 3 * u * u * t * p1 + 3 * u * t * t * p2 + t * t * t * p3;
};
const colorize = ({ r, g, b }: { r: string; g: string; b: string; }) => `#${r}${g}${b}`;
const colorizeRGB = ({ r, g, b }: { r: number; g: number; b: number; }) => `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

const interpolateColor = ({ colorA, colorB, percentage }: { colorA: { r: number; g: number; b: number; }; colorB: { r: number; g: number; b: number; }; percentage: number }): string => {
  const bezierPercentage = cubicBezier(percentage, 0, 0.5, 0.7, 1); // Adjust control points as needed
  const r_new = Math.round(colorA.r + (colorB.r - colorA.r) * bezierPercentage).toString(16).padStart(2, '0');
  const g_new = Math.round(colorA.g + (colorB.g - colorA.g) * bezierPercentage).toString(16).padStart(2, '0');
  const b_new = Math.round(colorA.b + (colorB.b - colorA.b) * bezierPercentage).toString(16).padStart(2, '0');
  return colorize({
    r: r_new,
    g: g_new,
    b: b_new
  });
};

const drawSample = ({ height, segmentWidth, gap, ctx, currentSegment, samplesRef, currentTime, duration }: { height: number, segmentWidth: number, gap: number, ctx: CanvasRenderingContext2D, currentSegment?: number, samplesRef: React.RefObject<number[]>, currentTime: number, duration: number }) => (sample: number, index: number) => {
  const barHeight = sample * height / 2;
  const x = index * (segmentWidth + gap);
  let color = 'white';
  const segmentLength = duration / samplesRef.current!.length;

    

  if (currentSegment !== undefined) {
    if (index < currentSegment) {
      color = colorizeRGB(ACTIVE_COLOR);
    } else if (index === currentSegment - 1) {
      const segmentProgress = (currentTime % segmentLength) / segmentLength;
      color = interpolateColor({ colorA: ACTIVE_COLOR, colorB: INACTIVE_COLOR, percentage: segmentProgress });
    } else if (index === currentSegment) {
      const segmentProgress = (currentTime % segmentLength) / segmentLength;
      color = interpolateColor({ colorA: INACTIVE_COLOR, colorB: ACTIVE_COLOR, percentage: segmentProgress });
    }
  }
  // Draw rounded rectangle manually
  drawRoundedRect(ctx, x, height / 2 - barHeight, segmentWidth, barHeight * 2, 0, color);
};

export const updateCurrentSegment = async (currentTime: number, duration: number, canvasRef: React.RefObject<HTMLCanvasElement>, samplesRef: React.RefObject<number[]>) => {
  if (!canvasRef.current || !samplesRef.current) return;

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const { segmentCount, segmentWidth, gap, height } = getCalculationSize(canvas);

  // Calculate current segment based on current time
  const currentSegment = Math.floor((currentTime / duration) * segmentCount);
  const data = samplesRef.current
  const maxSample = Math.max(...data);
  const normalizedSamples = data.map(s => s / maxSample);
  normalizedSamples.forEach(drawSample({ height, segmentWidth, gap, ctx, currentSegment, samplesRef, currentTime, duration }));
};
