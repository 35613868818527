import * as React from 'react';
import AudioHandler from '../components/audioPlayer/audioHandler';
import Waveform from '../components/audioPlayer/waveform';
import Display from '../components/audioPlayer/display';
import styled from 'styled-components';
import { getCalculationSize } from '../components/audioPlayer/waveformUtils';
import { parseTimestamp } from '../components/audioPlayer/audioHandlerUtils';


const Div = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  background-color: black;
  z-index: 100;
  padding: 5px 25px;
`;

export interface AudioMetadata {
  duration: number;
  data: number[] | null;
}

export interface TrackInfo {
  src: string;
  trackList: Track[];
  playlistName: string;
  playlistUrl: string;
  metadataUrl: string
}

interface AudioPlayerContextType {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  audioRef: React.RefObject<HTMLAudioElement>;
  switchToTrack: (index: number) => void;
  currentTrack: Track | null;
  setTrackInfo: React.Dispatch<React.SetStateAction<TrackInfo | null>>
  trackInfo: TrackInfo | null;

}

interface Track {
  title: string;
  timestamp: string;
}

const AudioPlayerContext = React.createContext<AudioPlayerContextType | null>(null);

export const AudioPlayerProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [audioMetadata, setAudioMetadata] = React.useState<AudioMetadata | null>(null);
  const [currentTrack, setCurrentTrack] = React.useState<Track | null>(null);
  const [trackInfo, setTrackInfo] = React.useState<TrackInfo | null>(null);


  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const samplesRef = React.useRef<number[]>([]);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!audioMetadata?.duration || !samplesRef.current || !canvasRef.current) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const { segmentCount, segmentWidth, gap } = getCalculationSize(canvasRef.current);
    const segmentIndex = Math.floor(x / (segmentWidth + gap));
    const newTime = (segmentIndex / segmentCount) * audioMetadata.duration;
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const switchToTrack = (index: number) => {
    if (!trackInfo) return;
    const track = trackInfo.trackList[index];
    const time = parseTimestamp(track.timestamp);
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
  }

  return (
    <AudioPlayerContext.Provider
      value={{
        audioRef,
        isPlaying,
        setIsPlaying,
        switchToTrack,
        currentTrack,
        setTrackInfo,
        trackInfo
      }}
    >
      {children}

      {trackInfo && (
        <Div>
          <AudioHandler
            setCurrentTime={setCurrentTime}
            currentTime={currentTime}
            onTrackChange={setCurrentTrack}
            audioMetadata={audioMetadata}
            setAudioMetadata={setAudioMetadata}
            audioRef={audioRef}
          />
          <Waveform
            currentTime={currentTime}
            audioMetaData={audioMetadata}
            canvasRef={canvasRef}
            samplesRef={samplesRef}
            onClick={handleClick}
          />
          <Display
            currentTrack={currentTrack}
            currentTime={currentTime}
            duration={audioMetadata?.duration}
            trackInfo={trackInfo}
          />
        </Div>
      )}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContext;
