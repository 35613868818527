import * as React from "react";
import { loadAudio, parseTimestamp } from "./audioHandlerUtils";
import AudioPlayerContext, { AudioMetadata, TrackInfo } from "../../context/AudioPlayerContext";
import { togglePlayPause } from "./buttonUtils";


interface AudioHandlerProps {
  setAudioMetadata: (data: AudioMetadata) => void;
  audioMetadata: AudioMetadata | null;
  setCurrentTime: (currentTime: number) => void;
  currentTime: number;
  onTrackChange: (track: Track | null) => void;
  audioRef: React.RefObject<HTMLAudioElement>;
}

interface Track {
  title: string;
  timestamp: string;
}

const AudioHandler: React.FC<AudioHandlerProps> = ({
  setCurrentTime,
  onTrackChange,
  currentTime,
  audioMetadata,
  setAudioMetadata,
}) => {

  const audioPlayerContext = React.useContext(AudioPlayerContext);

  if (!audioPlayerContext) {
    return null;
  }

  const {
    audioRef,
    isPlaying,
    setIsPlaying,
    trackInfo
  } = audioPlayerContext;



  React.useEffect(() => {
    const fetchAndDecodeAudio = async () => {
      if (!trackInfo) {
        return;
      }
      const audioData = await loadAudio(trackInfo.metadataUrl);
      if (audioData) {
        setAudioMetadata(audioData)
      }
    };

    fetchAndDecodeAudio();
  }, [trackInfo, setAudioMetadata]);


  React.useEffect(() => {
    if (!audioMetadata || !trackInfo) {
      return
    }
    const { trackList } = trackInfo;
    const currentTrackIndex = trackList.findIndex((track, index) => {
      const trackStartTime = parseTimestamp(track.timestamp);
      let trackEndTime;
      if (index === trackList.length - 1) {
        trackEndTime = audioMetadata.duration;
      } else {
        trackEndTime = parseTimestamp(trackList[index + 1].timestamp);
      }
      return currentTime >= trackStartTime && currentTime < trackEndTime;
    });
    if (currentTrackIndex !== -1) {
      onTrackChange(trackList[currentTrackIndex]);
    } else {
      onTrackChange(null);
    }
  }, [currentTime, audioMetadata?.duration, trackInfo, onTrackChange]);

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    const time = (e.target as HTMLAudioElement).currentTime;
    setCurrentTime(time);
  };

  React.useEffect(() => {
    if (trackInfo) {
      audioRef.current?.play()
      setIsPlaying(true)
    }
  }, [trackInfo])

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        togglePlayPause(isPlaying, audioRef.current, setIsPlaying)
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [setIsPlaying, isPlaying]);

  if (!trackInfo) {
    return null;
  }

  return (
    <audio
      ref={audioRef}
      src={trackInfo.src}
      onTimeUpdate={handleTimeUpdate}
    />
  );
};

export default AudioHandler;
