// custom typefaces
import "@fontsource-variable/montserrat";
import "@fontsource/merriweather";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";

// Highlighting for code blocks
import "prismjs/themes/prism.css";

import { AudioPlayerProvider } from "./src/context/AudioPlayerContext";
import React from "react";
import ReactDOM from "react-dom";
import Layout from "./src/components/layout";

export const wrapRootElement = ({ element }: { element: any }) => (
  <AudioPlayerProvider>{element}</AudioPlayerProvider>
);
