import { TrackInfo } from "../../context/AudioPlayerContext";

export const togglePlayPause = (
  isPlaying: boolean,
  audioElement: HTMLAudioElement | null,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (isPlaying) {
    audioElement?.pause();
  } else {
    audioElement?.play();
  }
  setIsPlaying(!isPlaying);
};

export const areTrackInfosSame = (a: TrackInfo | null, b: TrackInfo): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};